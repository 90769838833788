.infoTabs {
  height: 100%;

  > :global(.ant-tabs-content-holder) {
    display: flex;
    align-items: flex-end;

    > :global(.ant-tabs-content) {
      height: 100%;
      overflow: hidden;
    }
  }
}

.commentTab {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;