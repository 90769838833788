.overlay {
    padding: 20px;
    background-color: white;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 10px;
    width: 480px;
}

.modal {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100 !important;
}

.suffix {
    svg {
        color: grey !important;
        opacity: 0.6;
    }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;