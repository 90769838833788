.progress {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: rgba(0, 68, 204, 0.05);
  border: 1px solid rgba(0, 68, 204, 0.2);

  &.danger {
    border: 1px solid rgba(255, 77, 79, 0.2);
  }

  &, .mainBar, .secondaryBar, .backgroundBar {
    height: 14px;
    box-sizing: content-box;
    border-radius: 20px;
  }

  .mainBar, .secondaryBar, .backgroundBar {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    min-width: 0;
  }

  .backgroundBar {
    width: 100%;
  }

  .mainBar {
    background: rgb(0, 68, 204);
  }

  .secondaryBar {
    background: rgba(0, 68, 204, 0.2);
  }

  .danger {
    &.mainBar {
      background: rgba(255, 77, 79);
    }

    &.secondaryBar {
      background: rgba(255, 77, 79, 0.2);
    }
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;