.containerXl {
  height: 100%;

  > :global(.ant-card-body) {
    height: 100%;
  }
}
.rowContainer {
  row-gap: 12px;
  height: 100%;
}

.colCreatedAt {
  flex: 1 1 100%;
  text-align: right;
}

@media (min-width: 772px) {
  .colCreatedAt {
    flex: none;
  }
}

.colFormQuestions {
  flex: 0 1 auto;
}

.colDivider {
  display: none;
}

.colContainerRight {
  flex: 0 1 auto;
  width: 100%;
  min-width: 380px;
}

.colContainerLeft {
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%;
}

@media (min-width: 1200px) {
  .containerXl {
    min-height: 600px;

    .colDivider {
      display: initial;
    }

    .rowContainer {
      flex-flow: nowrap;
    }

    .colContainerRight {
      width: 590px;
    }

    .colContainerLeft {
      width: auto;
      flex-basis: 0;

      .colFormQuestions {
        overflow-y: scroll;
        padding-right: 4px;
      }
    }
  }
}

@media (min-width: 1900px) {
  .containerXxl {
    .colDivider {
      display: initial;
    }

    .rowContainer {
      flex-flow: nowrap;
    }

    .colContainerRight {
      width: 585px;
      padding-top: 0;
    }

    .colContainerLeft {
      width: auto;
    }
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;