@import (reference) '../../../../styles/index.less';

.card {
  display: block;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 68, 204, 0.10);
  border-left: 6px solid;
  background: rgba(0, 68, 204, 0.05);
  word-break: break-word;
  margin-bottom: 12px;

  &,&:hover, :active {
    color: @text-color;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;