.containerLoadingFullHeight {
  flex: auto;
  overflow: hidden;

  > :global(.ant-spin-container) {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
}

.replyTo {
  width: 100%;
  background-color: rgb(243, 243, 243);
  border-radius: 10px;
  padding: 10px 15px;
  position: relative;
  overflow: hidden;

  .line {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: #20bb84;
  }

  h3 {
    margin: 0px !important;
  }

  p {
    margin-bottom: 0;
  }
}

.fbCounter {
  margin-top: 4px;

  div {
    align-items: center;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;