.card {
  > :global(.ant-card-head) {
    padding: 0 16px;
    display: flex;
    align-items: center;

    > :global(.ant-card-head-wrapper) {
      width: 100%;
    }

     > :global(.ant-card-head-wrapper > .ant-card-head-title) {
       padding: 0;
       width: 100%;
     }
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;